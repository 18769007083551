body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Recoleta-Regular";
  src: local("Recoleta-Regular"),
    url(./fonts/Recoleta-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Recoleta-Black";
  src: local("Recoleta-Black"), 
    url(./fonts/Recoleta-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Recoleta-Thin";
  src: local("Recoleta-Thin"),
    url(./fonts/Recoleta-Thin.ttf) format("truetype");
}

@font-face {
  font-family: 'PPEiko-LightItalic';
  src: local('PPEiko-LightItalic'),
    url(./fonts/PPEiko-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'FoundersGrotesk-Light';
  src: local('FoundersGrotesk-Light'),
    url(./fonts/FoundersGroteskTest-Light.otf) format('opentype');
    
}

@font-face {
  font-family: 'SpaceGrotesk-Light';
  src: local('SpaceGrotesk-Light'),
    url(./fonts/SpaceGrotesk-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'FigGrotesk-Book';
  src: local('FigGrotesk-Book'),
    url(./fonts/FigGrotesk0.3Trial-Book.otf) format('opentype');   
}


@keyframes fadeInUp {
  0% {
    transform: translateY(10%);
    opacity: 0.8;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.fadeInUp {
  animation: 1.5s fadeInUp;
}

@keyframes fadeInUpBig {
  0% {
    transform: translateY(60%);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
.fadeInUpBig {
  animation: 1s fadeInUpBig;
}


@keyframes fadeInUpDos {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUpDos {
  animation: 1.5s fadeInUpDos;
  animation-delay: 0.1s;
}


@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-24px);}
	60% {transform: translateY(-16px);}
}
.bounce {
  animation: bounce 2s ease infinite;
  animation-delay: 0.5s;
  animation-iteration-count: 2;
}